module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@4.15.0_babel-plugin-styled-components@1.13.3_gatsby@3.15.0_re_hky7ltt2zyveefidlsdy4qdd5q/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-offline@4.15.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@3.15.0_gatsby@3.15.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@3.15.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-135755854-1"],"gtagConfig":{"optimize_id":"GTM-TLGS83S","anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@3.15.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHXXCX7","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@4.12.0_gatsby@3.15.0_react-dom@17.0.2_react@17.0.2/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-typography@3.15.0_gatsby@3.15.0_react-dom@17.0.2_react-typography@0.16.23_react@17.0.2_typography@0.16.24/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.ts"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Drew's Workbench","short_name":"Drew's Workbench","description":"My Blog","start_url":"/","background_color":"#2b2e3c","theme_color":"#3498DB","display":"standalone","icon":"src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"71f41642a61b267d5c613328eec98495"},
    }]
